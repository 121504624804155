exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-commercial-window-repair-calgary-js": () => import("./../../../src/pages/services/commercial-window-repair-calgary.js" /* webpackChunkName: "component---src-pages-services-commercial-window-repair-calgary-js" */),
  "component---src-pages-services-home-window-repair-calgary-js": () => import("./../../../src/pages/services/home-window-repair-calgary.js" /* webpackChunkName: "component---src-pages-services-home-window-repair-calgary-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-other-glass-services-js": () => import("./../../../src/pages/services/other-glass-services.js" /* webpackChunkName: "component---src-pages-services-other-glass-services-js" */),
  "component---src-pages-services-storm-doors-calgary-js": () => import("./../../../src/pages/services/storm-doors-calgary.js" /* webpackChunkName: "component---src-pages-services-storm-doors-calgary-js" */)
}

